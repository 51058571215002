import config from '@configs/index'
import { ICategory } from '@features/category/query'
import { setFooter, setHeader, setMegaMenu, setPreFooter } from '@features/cms/slice'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { STORES, site } from '@foundation/constants/site'
import { USER_SEGMENT_COOKIE_NAME } from '@foundation/constants/user'
import { getSiteData } from '@foundation/hooks/useSite'
import { INIT_SITE_SUCCESS_ACTION } from '@redux/actions/site'
import { SiteInfo } from '@redux/reducers'
import wrapper from '@redux/store'
import { ICommerceHclPage, IFooter, IHeader, IMegaMenu, IPage, IPageStaticLanding, IQueryParams } from '@typesApp/cms'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { IServerProduct } from '@typesApp/product'
import { ISeo } from '@typesApp/seo'
import { parseCookies } from '@utils/Cookies'
import { StoreManagerLoaders } from '@utils/SSR/stateManagment/stateManagerHandler'
import { StoreManagerLoadersTypeMap } from '@utils/SSR/stateManagment/types'
import { hashCode } from '@utils/helpers'
import { PAGE_TYPES } from '@utils/httpHeadersUtils'
import { getServerLocale } from '@utils/locale'
import axios, { AxiosError } from 'axios'
import { GetServerSidePropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'
import { CHECKOUT_CMS_PAGEID, SIGNIN_CMS_PAGEID, ORDER_CONFIRMATION_CMS_PAGEID } from '@constants/common'
import { FeatureFlagsResponse } from '@foundation/featureFlag/constants'
import { categoryApiService } from '@foundation/apis/category/category.ssr.service'
import { DomainLocale } from 'next/dist/server/config'

interface IDispatchCommonComponents {
  siteDataRes: SiteInfo
  locale: string | undefined
  header: IHeader
  footer: IFooter
  megaMenu: IMegaMenu
  preFooter: IPlacement<IPlacementItem> | null
  rest: unknown
}

export interface IPageBaseProps {
  siteDataRes: SiteInfo
  locale: string | undefined
  header: IHeader
  megaMenu: IMegaMenu
  footer: IFooter
  preFooter: IPlacement<IPlacementItem> | null
  // todo remove question marks
  domainLocales?: DomainLocale | null
  locationOrigin?: string
  isLocaleDomain: boolean
  is404?: boolean
  plpCommerce?: IPageStaticLanding | ICommerceHclPage | null
  pageDataLanding?: IPageStaticLanding | ICommerceHclPage | null
  featureFlags?: FeatureFlagsResponse
}

export const dispatchCommonComponents = ({
  locale,
  siteDataRes,
  header,
  megaMenu,
  footer,
  preFooter,
  rest,
}: IDispatchCommonComponents) => {
  const { store } = wrapper.useWrappedStore(rest)
  const state = store.getState()
  const isLocaleTheSame = locale?.toLowerCase() === siteDataRes?.locale.toLowerCase()

  if (!state.site.currentSite || !isLocaleTheSame) {
    store.dispatch(INIT_SITE_SUCCESS_ACTION(siteDataRes))
  }

  if (!state.cms.header || !isLocaleTheSame) {
    store.dispatch(setHeader(header))
  }

  if (!state.cms.megaMenu || !isLocaleTheSame) {
    store.dispatch(setMegaMenu(megaMenu))
  }

  if (!state.cms.footer || !isLocaleTheSame) {
    store.dispatch(setFooter(footer))
  }

  if (!state.cms.preFooter || !isLocaleTheSame) {
    store.dispatch(setPreFooter(preFooter))
  }
}

interface IFetchData {
  pageData: IPage | IPageStaticLanding | ICommerceHclPage | AxiosError | null
  siteDataRes: SiteInfo
  locale: string | undefined
  header: IHeader
  megaMenu: IMegaMenu
  footer: IFooter
  preFooter: IPlacement<IPlacementItem> | null
}

export const fetchData = async ({
  context,
  stateManager,
  sectionLimit,
  pageType,
  product,
  breadcrumb,
  searchParams,
  seoData,
  categoryData,
  isLocaleDomain,
}: {
  context: GetServerSidePropsContext
  stateManager: StoreManagerLoaders<keyof StoreManagerLoadersTypeMap>
  sectionLimit?: number
  pageType?: string
  product?: IServerProduct | null
  breadcrumb?: string[] | null
  searchParams?: ParsedUrlQuery
  seoData?: ISeo
  categoryData?: ICategory[] | null
  isLocaleDomain: boolean
}): Promise<IFetchData> => {
  const { query, resolvedUrl, req } = context
  const locale = getServerLocale(isLocaleDomain, getLocale(context.locale))
  const lowerCaseLocale = locale.toLowerCase()
  const cookies = parseCookies(req)

  const storeInfoManager = stateManager.get('storeInfo')
  const cmsDataManager = stateManager.get('cms')

  const hasSectionLimit = sectionLimit && Number.isInteger(sectionLimit) && sectionLimit > 0

  const params = {
    ...STORES[lowerCaseLocale],
    locale,
    // Get 'header_search', 'header_search_trending_now' on client side
    excludePlacements: ['header_search', 'header_search_trending_now'],
    noEnrich: undefined,
    previewDate: query?.previewDate as string | undefined,
    filterRulesLocaleOverride: query?.filterRulesLocaleOverride as string | undefined,
  }

  let commonPromises: any = []

  const siteDataResFromStore = storeInfoManager.getData(lowerCaseLocale)
  if (!siteDataResFromStore) {
    commonPromises.push(getSiteData(locale, site) as unknown as SiteInfo)
  } else {
    commonPromises.push(Promise.resolve())
  }

  const cmsDataFromStore = cmsDataManager.getData(lowerCaseLocale)
  if (!cmsDataFromStore?.header) {
    commonPromises.push(cmsApiService.getHeader(params))
  } else {
    commonPromises.push(Promise.resolve())
  }

  if (!cmsDataFromStore?.megaMenu) {
    commonPromises.push(cmsApiService.getMegaMenu(params))
  } else {
    commonPromises.push(Promise.resolve())
  }

  if (!cmsDataFromStore?.footer) {
    commonPromises.push(cmsApiService.getFooter(params))
  } else {
    commonPromises.push(Promise.resolve())
  }

  const pageDataManager = stateManager.get('pageData')
  const pageDataFromStore = pageDataManager.getData(lowerCaseLocale)
  const userSegment = cookies?.[USER_SEGMENT_COOKIE_NAME] ?? ''
  const pathHashCode = hashCode(locale + resolvedUrl + userSegment)
  const cachedPageData = pageDataFromStore?.[pathHashCode]

  if (!cachedPageData) {
    //creates promises's array for each page
    switch (pageType) {
      case PAGE_TYPES.HOME:
        commonPromises.push(
          cmsApiService.getHomePage({
            ...params,
            noEnrich: 'hotZones',
            ...(hasSectionLimit ? { 'section.main.limit': sectionLimit.toString() } : {}),
          })
        )
        break
      case PAGE_TYPES.STATIC_PAGE:
        const pageId = (query.pageName as Array<string | undefined>).filter(el => el !== undefined).join('/')
        commonPromises.push(
          cmsApiService.getPageStaticLanding({
            ...params,
            pageId,
            noEnrich: 'hotZones',
          })
        )
        break
      case PAGE_TYPES.NOT_FOUND:
        // I have reused the getPageStaticLanding fuction as it
        // uses the same cms API call structure. Should this
        // change in the future, a new function will be needed
        commonPromises.push(
          cmsApiService.getPageStaticLanding({
            ...params,
            pageId: 'ops-page',
            noEnrich: 'hotZones',
          })
        )
        break
      case PAGE_TYPES.PRODUCT_LIST_PAGE:
        if (!breadcrumb || !locale || !categoryData) break
        const plpParams = {
          storeId: STORES[lowerCaseLocale].storeId,
          langId: STORES[lowerCaseLocale].langId,
          externalId: categoryData?.[categoryData.length - 1]?.identifier,
          ...getCommercePageDataArgs(breadcrumb, locale, searchParams),
        }
        commonPromises.push(cmsApiService.getPLPCommerce(plpParams))
        break
      case PAGE_TYPES.SEARCH_RESULT:
        const searchPageParams = {
          storeId: STORES[lowerCaseLocale].storeId,
          langId: STORES[lowerCaseLocale].langId,
          ...getCommercePageDataArgs(breadcrumb || [], locale, searchParams),
        }
        commonPromises.push(cmsApiService.getPLPCommerce(searchPageParams))
        break
      case PAGE_TYPES.PRODUCT_PAGE:
        if (!breadcrumb || !seoData || !product || !locale) break
        const pdpParams = {
          storeId: STORES[lowerCaseLocale].storeId,
          langId: STORES[lowerCaseLocale].langId,
          country: STORES[lowerCaseLocale].country,
          externalId: product.cluster && product.cluster[0] ? product.cluster[0].name : seoData?.tokenExternalValue,
          ...getCommercePageDataArgs(breadcrumb, locale, searchParams),
        }
        commonPromises.push(cmsApiService.getPDPCommerce(pdpParams))
        break
      case PAGE_TYPES.SITEMAP:
        /* Akamai sends a comma-separated string of all origin hostnames for each jump: "uat.clearly.ca, uat.clearly.ca" */
        const { req } = context
        const xForwardedHost = req?.headers?.['x-forwarded-host'] ?? []
        const protocol = req.headers['x-forwarded-proto'] ?? 'https'
        const xForwardedHostList = Array.isArray(xForwardedHost) ? xForwardedHost : xForwardedHost?.split(',')
        const hostname = xForwardedHostList[0] || req?.headers?.host || config.cmsApiUrl
        const sitemapParams = {
          locale: locale ?? config.defaultLocale,
          country: STORES[lowerCaseLocale].country,
          hostname: protocol + '://' + hostname,
        }
        commonPromises.push(cmsApiService.getSitemapPage(sitemapParams))
        break
      case PAGE_TYPES.SIGNIN:
        commonPromises.push(
          cmsApiService.getPageStaticLanding({
            ...params,
            pageId: SIGNIN_CMS_PAGEID,
            noEnrich: 'hotZones',
          })
        )
        break
      case PAGE_TYPES.CHECKOUT:
        commonPromises.push(
          cmsApiService.getPageStaticLanding({
            ...params,
            pageId: CHECKOUT_CMS_PAGEID,
            noEnrich: 'hotZones',
          })
        )
        break
      case PAGE_TYPES.ORDER_CONFIRMATION:
        commonPromises.push(
          cmsApiService.getPageStaticLanding({
            ...params,
            pageId: ORDER_CONFIRMATION_CMS_PAGEID,
            noEnrich: 'hotZones',
          })
        )
        break
    }

    // todo remove when the category response is added in BFF
    const seoPageType = pageType ?? PAGE_TYPES.NOT_FOUND
    if ([PAGE_TYPES.CATEGORY_PAGE, PAGE_TYPES.PRODUCT_LIST_PAGE].includes(seoPageType)) {
      const categoryResponse = categoryApiService.getCategories({
        identifier: seoData?.tokenExternalValue,
        storeId: STORES[lowerCaseLocale].storeId,
        langId: STORES[lowerCaseLocale].langId,
      })

      commonPromises.push(categoryResponse)
    } else {
      commonPromises.push(Promise.resolve())
    }
  } else {
    commonPromises.push(Promise.resolve())
  }

  const [siteDataRes, header, megaMenu, footer, pageData, categoryResponse] = await Promise.all(commonPromises)

  // todo ideally we should be checking for all requirements to have been successfully loaded
  // todo then deciding to load the _error page or continue with the page
  // todo just passing the response errors causing a serialization error from server to client

  if (!siteDataRes && !siteDataResFromStore) {
    throw new Error('store info data not found')
  }

  const isCacheEnabled = (siteDataResFromStore ?? siteDataRes)?.xStoreCfg.isCacheEnabled ?? false

  storeInfoManager.setData(siteDataResFromStore ?? (isCacheEnabled ? siteDataRes : null), lowerCaseLocale)

  let preFooter = null
  //get footer_before_footer for pages that need it
  if (!axios.isAxiosError(pageData) && pageData) {
    const placements =
      pageType === PAGE_TYPES.PRODUCT_PAGE || pageType === PAGE_TYPES.PRODUCT_LIST_PAGE
        ? pageData.commercePlacements
        : pageData.contentPlacements

    preFooter = placements?.find(placement => placement.name === 'footer_before_footer') || null
  }

  cmsDataManager.setData(
    {
      header: config.isCacheEnabled ? cmsDataFromStore?.header ?? header : null,
      footer: config.isCacheEnabled ? cmsDataFromStore?.footer ?? footer : null,
      megaMenu: config.isCacheEnabled ? cmsDataFromStore?.megaMenu ?? megaMenu : null,
      // header: config.isCacheEnabled ? cmsDataFromStore?.header ?? responseOrNull(header) : null,
      // footer: config.isCacheEnabled ? cmsDataFromStore?.footer ?? responseOrNull(footer) : null,
      // megaMenu: config.isCacheEnabled ? cmsDataFromStore?.megaMenu ?? responseOrNull(megaMenu) : null,
      preFooter: config.isCacheEnabled ? preFooter : null,
    },
    lowerCaseLocale
  )

  // todo remove when the category response is added in BFF
  if (!cachedPageData && categoryResponse) {
    pageData.category = categoryResponse?.contents?.[0] ?? null
  }

  const pageDataToServe = cachedPageData ? cachedPageData : axios.isAxiosError(pageData) ? null : pageData

  if (isCacheEnabled) {
    pageDataManager.setData(
      {
        [pathHashCode]: pageDataToServe,
      },
      lowerCaseLocale
    )
  }

  return {
    pageData: pageDataToServe,
    siteDataRes: siteDataResFromStore ?? siteDataRes ?? null,
    locale,
    header: cmsDataFromStore?.header ?? header ?? null,
    megaMenu: cmsDataFromStore?.megaMenu ?? megaMenu ?? null,
    footer: cmsDataFromStore?.footer ?? footer ?? null,

    // header: cmsDataFromStore?.header ?? responseOrNull(header) ?? null,
    // megaMenu: cmsDataFromStore?.megaMenu ?? responseOrNull(megaMenu) ?? null,
    // footer: cmsDataFromStore?.footer ?? responseOrNull(footer) ?? null,
    preFooter,
  }
}

const responseOrNull = (response: any) => {
  return response?.data ?? null
}

export const getLocale = (locale?: string): string => {
  const defaultLocale = config.defaultLocale
  if (!locale) return defaultLocale
  return config.availableLocales.find(l => l.toLowerCase() === locale.toLowerCase()) || defaultLocale
}

const getCommercePageDataArgs = (breadcrumb: string[], locale: string, searchParams?: ParsedUrlQuery) => {
  const previewDate = searchParams?.previewDate as string
  const filterRulesLocaleOverride = searchParams?.filterRulesLocaleOverride as string
  const pdpCommerceArgs: IQueryParams = {
    locale,
    country: STORES[locale.toLowerCase()].country,
    breadcrumb,
    previewDate,
    filterRulesLocaleOverride,
    noEnrich: 'hotZones',
  }

  return pdpCommerceArgs
}

export const isCmPreviewEnabled = (query: ParsedUrlQuery): boolean => {
  return ['filterRulesLocaleOverride', 'previewDate'].every(key => Object.keys(query).includes(key))
}
